import "./Projects.css";
import React from "react";
import SwipeBox from "./SwipeBox";

export default function Projects() {
  return (
    <div className="Projects" id="Projects">
      <div className="Frame Main P3">
        <SwipeBox />
      </div>
    </div>
  );
}
