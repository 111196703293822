import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function DHCSSlide() {
  const dhcs = new Map<string, string>();
  dhcs.set("lofi", "1fQ8UyzUGmj0XATqQ09qD61tXXzseHGiD");
  dhcs.set("midfi", "1No0s-aRqPhQlGt-H2hnR7UdznLbLVk8C");
  dhcs.set("video", "1Ikv-lc-qyFq_me8vHvFxdLakI011L8wj");
  dhcs.set("proc", "16Vr1sYI4_eiWSWo18AgcLuEa_3vZx9Jf");

  const [project, setProject] = useState("lofi");

  return (
    <div className="MediaWrapper">
      <iframe
        src={
          "https://drive.google.com/file/d/" + dhcs.get(project) + "/preview"
        }
        className="DHCS"
      />
      <div className="Selector">
        <button
          className={project == "lofi" ? "active" : "inactive"}
          onClick={() => setProject("lofi")}
        >
          Lo-Fi
        </button>

        <button
          className={project == "midfi" ? "active" : "inactive"}
          onClick={() => setProject("midfi")}
        >
          Mid-Fi
        </button>

        <button
          className={project == "video" ? "active" : "inactive"}
          onClick={() => setProject("video")}
        >
          Video
        </button>

        <button
          className={project == "proc" ? "active" : "inactive"}
          onClick={() => setProject("proc")}
        >
          Processing
        </button>
      </div>
    </div>
  );
}

export const content = [
  <DHCSSlide key="DHCS" />,

  <div key="ArcGPT" className="MediaWrapper">
    <iframe
      src="https://drive.google.com/file/d/1WjFeVQoC5eZAIxdDucKChlc9o1x2XTbv/preview"
      className="ArcGPT"
    />
  </div>,

  <div key="Eruca" className="MediaWrapper">
    <iframe
      src="https://drive.google.com/file/d/1xkSRWH5JUQfWu-oZbkZD9cWHEhCTfgRV/preview"
      className="Eruca"
    />
    <a
      href="https://github.com/Yousif-Alnajjar/eruca"
      target="_blank"
      rel="noreferrer"
      className="Link Over font-italic"
    >
      <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} /> Open in
      Github
    </a>
  </div>,

  <div key="Calarts" className="MediaWrapper">
    <iframe
      src="https://drive.google.com/file/d/1bvLPc5YEkHTz8IWVRkOvVbrdv-u3biOc/preview"
      className="InTheZone"
    />
  </div>,

  <div key="Sweater" className="MediaWrapper">
    <iframe
      src="https://drive.google.com/file/d/1G9fNnZGl0j6CbJ5zueMIFXzfbT-XQsjh/preview"
      className="Sweater"
    />
    <a
      href="https://github.com/Yousif-Alnajjar/christmas-sweater"
      target="_blank"
      rel="noreferrer"
      className="Link Over font-italic"
    >
      <FontAwesomeIcon icon={faArrowUpRightFromSquare as IconProp} /> Open in
      Github
    </a>
  </div>,

  <div key="Rush" className="MediaWrapper">
    <iframe
      src="https://drive.google.com/file/d/1fR6prtmcnUSn9yS3amiT8eijLI-xCupI/preview"
      className="Rush"
    />
  </div>,
];

export interface Text {
  title: string;
  tags: string;
  bodyText: React.ReactNode;
}

export const text: Text[] = [
  {
    title: "Designing Human-Centered Software Individual Coursework",
    tags: "UI/UX, Prototyping, Figma, Processing",
    bodyText: (
      <p>
        The first two assignments were to create a{" "}
        <span className="highlight">lo-fi prototype</span> and{" "}
        <span className="highlight">iterated mid-fi prototype</span> for a
        package shipping interface. The third assignment was to create a{" "}
        <span className="highlight">video prototype</span> for an idea tailored
        to the CMU community. The final individual task was to create a{" "}
        <span className="highlight">code-based prototype</span> utilizing two
        different input methods.{" "}
      </p>
    ),
  },
  {
    title: "Multi-Agent GPT Interface",
    tags: "Rapid Prototyping, Figma, UI/UX",
    bodyText: (
      <p>
        I was commissioned to create a prototype{" "}
        <span className="highlight">in 2 hours</span> for a Slack-like interface
        that uses multiple GPT agents. This was used to{" "}
        <span className="highlight">guage interest</span> on Twitter for a
        potential start-up.
      </p>
    ),
  },
  {
    title: "Eruca",
    tags: "Python, ML, UI",
    bodyText: (
      <p>
        Final project for &apos;Fundamentals of Programming&apos; at CMU. I
        created a{" "}
        <span className="highlight">
          Long Short-Term Memory model from scratch
        </span>{" "}
        using only the <span className="highlight">NumPy</span> library to
        predict the price of Bitcoin. The{" "}
        <span className="highlight">user interface</span> allowed for the
        selection of a risk level and executed trades based on the predicted
        price and chosen risk level.
      </p>
    ),
  },
  {
    title: "Calarts Visual Elements of UI Design",
    tags: "UI, Design, Adobe XD",
    bodyText: (
      <p>
        I took this Coursera class that gave me a brief introduction to the{" "}
        <span className="highlight">visual side</span> of user interfaces and
        the role a designer plays in creating them. This course centered around
        a project that was built up throughout the various lessons, starting
        from putting together a <span className="highlight">mood board</span>,
        to creating a sample <span className="highlight">pattern library</span>,
        to finally designing two screens of{" "}
        <span className="highlight">the UI</span>.
      </p>
    ),
  },
  {
    title: "Ugly Christmas Sweater",
    tags: "Python, Django, Interaction Design, Product Development",
    bodyText: (
      <p>
        Winner of{" "}
        <span className="highlight">&apos;Spiciest Meme&apos; prize</span> at
        CMU Hack112 F22. During this{" "}
        <span className="highlight">24 hour hackathon</span>, I worked with a{" "}
        <span className="highlight">hardware-specialized team</span> to create a{" "}
        <span className="highlight">web interface</span> for users to create a
        meme to be displayed on a Christmas sweater. Note that while the video
        uses a Google form, the final submission incorporated the web UI
        available on Github.
      </p>
    ),
  },
  {
    title: "SigEp Rush Calendar",
    tags: "Figma, Design, Collaboration",
    bodyText: (
      <p>
        I worked with a fraternity brother to create these{" "}
        <span className="highlight">graphics for our Instagram</span> page in
        order to promote our rush events. The attendance for Spring rush was
        noted to be <span className="highlight">higher than usual</span>, and
        many members that joined in S23 cited these graphics as{" "}
        <span className="highlight">the reason they attended</span> some events.
      </p>
    ),
  },
];
