import "./Navbar.css";
import React from "react";

interface NavbarProps {
  activePage: string;
  onActivePageChange: (page: string) => void;
}

export default function Navbar({ activePage, onActivePageChange }: NavbarProps) {
  function changePage(page: string) {
    onActivePageChange(page);
    window.location.href = "#" + page;
  }

  return (
    <div className="Navbar">
      <div className="Selector font-italic">
        <button className={activePage === "Home" ? "active" : "inactive"} onClick={() => changePage("Home")}>
          Home
        </button>

        <button className={activePage === "About" ? "active" : "inactive"} onClick={() => changePage("About")}>
          About
        </button>

        <button className={activePage === "Projects" ? "active" : "inactive"} onClick={() => changePage("Projects")}>
          Projects
        </button>
      </div>
    </div>
  );
}