import "./App.css";
import React, { useState, useEffect, useCallback } from "react";
import ReactDOM from "react-dom/client";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Projects from "./components/Projects";
import Mobile from "./components/Mobile";
import { InView } from "react-intersection-observer";
import { isMobile } from "react-device-detect";

const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

const App = () => {
  const [isPortrait, setIsPortrait] = useState(false);
  const [activePage, setActivePage] = useState("Home");

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: portrait)");

    setIsPortrait(mediaQuery.matches);

    const handleOrientationChange = (event: MediaQueryListEvent) => {
      setIsPortrait(event.matches);
    };

    mediaQuery.addListener(handleOrientationChange);

    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);

  const handleActivePageChange = useCallback((page: string) => {
    setActivePage(page);
  }, []);

  return isPortrait || isMobile ? (
    <Mobile />
  ) : (
    <>
      <Navbar
        activePage={activePage}
        onActivePageChange={handleActivePageChange}
      />
      <InView
        as="div"
        onChange={(inView) => inView && handleActivePageChange("Home")}
        threshold={1}
      >
        <Home />
      </InView>
      <InView
        as="div"
        onChange={(inView) => inView && handleActivePageChange("About")}
        threshold={1}
      >
        <About />
      </InView>
      <InView
        as="div"
        onChange={(inView) => inView && handleActivePageChange("Projects")}
        threshold={1}
      >
        <Projects />
      </InView>
    </>
  );
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
