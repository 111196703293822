import "./SwipeBox.css";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { content, text } from "./ProjectData";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function SwipeBox() {
  return (
    <div className="SwipeBox">
      <Swiper
        simulateTouch={false}
        centeredSlides={true}
        slidesPerView={1.5}
        effect={"coverflow"}
        spaceBetween={50}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
      >
        {content.map((slideContent, index) => (
          <SwiperSlide key={index} className="Frame">
            <div className="Card">
              <div className="Content">{slideContent}</div>
              <div className="Text">
                <h2 className="font-title">{text[index].title}</h2>
                <p className="font-italic">{text[index].tags}</p>
                <div className="font-text">{text[index].bodyText}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
