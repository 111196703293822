/* eslint-disable */
import * as THREE from "three";
import * as React from "react";
import { useRef, useState } from "react";
import { Canvas, useFrame, ThreeEvent } from "@react-three/fiber";
import { Edges } from "@react-three/drei";
import { LayerMaterial, Depth, Fresnel } from "lamina";

function CursorShape(props: JSX.IntrinsicElements["mesh"]) {
  const ref = useRef<THREE.Mesh>(null!);
  const [gradient, setGradient] = useState(0.6);
  const [hovered, hover] = useState(false);
  const [clicked, click] = useState(false);
  const [scaleTarget, setScaleTarget] = useState(1);

  // Rotate mesh every frame
  useFrame((state) => {
    ref.current.rotation.x += 0.012;
    ref.current.rotation.y += 0.008;
    ref.current.rotation.z += 0.006;

    // Animate scale smoothly
    if (scaleTarget !== ref.current.scale.x) {
      const scaleDiff = scaleTarget - ref.current.scale.x;
      const scaleStep = scaleDiff * 0.05;
      ref.current.scale.set(
        ref.current.scale.x + scaleStep,
        ref.current.scale.y + scaleStep,
        ref.current.scale.z + scaleStep
      );
    }
  });

  const handleClick = (event: ThreeEvent<MouseEvent>) => {
    click(!clicked);
    setScaleTarget(clicked ? 1 : 0.5);
  };

  // Create cursor shape
  const cursorShape = new THREE.Shape();
  cursorShape.moveTo(-1.125, 0);
  cursorShape.lineTo(0, 2.25);
  cursorShape.lineTo(1.125, 0);
  cursorShape.lineTo(0.375, 0);
  cursorShape.lineTo(0.375, -1.875);
  cursorShape.lineTo(-0.375, -1.875);
  cursorShape.lineTo(-0.375, 0);
  cursorShape.lineTo(-1.125, 0);

  const extrudeSettings = {
    steps: 2,
    depth: 0.6,
    bevelEnabled: false,
  };

  const geometry = new THREE.ExtrudeGeometry(cursorShape, extrudeSettings);

  return (
    <mesh
      {...props}
      ref={ref}
      onClick={handleClick}
      onPointerOver={(event) => {
        hover(true);
        setGradient(1);
      }}
      onPointerOut={(event) => {
        hover(false);
        setGradient(0.6);
      }}
    >
      <primitive attach="geometry" object={geometry} />
      <LayerMaterial toneMapped={false}>
        <Depth
          colorA="#ff0080"
          colorB="black"
          alpha={1}
          mode="normal"
          near={0.5 * gradient}
          far={0.5}
          origin={[0, 0, 0]}
        />
        <Depth
          colorA="blue"
          colorB="#f7b955"
          alpha={1}
          mode="add"
          near={2 * gradient}
          far={2}
          origin={[0, 1, 1]}
        />
        <Depth
          colorA="green"
          colorB="#f7b955"
          alpha={1}
          mode="add"
          near={3 * gradient}
          far={3}
          origin={[0, 1, -1]}
        />
        <Depth
          colorA="white"
          colorB="red"
          alpha={1}
          mode="overlay"
          near={1.5 * gradient}
          far={1.5}
          origin={[1, -1, -1]}
        />
        <Fresnel
          mode="add"
          color="white"
          intensity={0.5}
          power={1.5}
          bias={0.05}
        />
      </LayerMaterial>
      <Edges color="white" />
    </mesh>
  );
}

export default function Cursor() {
  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <CursorShape position={[0, 0, 0]} scale={[0.5, 0.5, 1]} />
    </Canvas>
  );
}
