import React from "react";
import "./Mobile.css";

export default function Mobile() {
  return (
    <div className="Mobile">
      <div className="Frame Main">
        <p className="font-title">
          Mobile-optimized site will be available soon.
        </p>
      </div>
    </div>
  );
}
