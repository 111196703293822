import "./About.css";
import React, { useState, useEffect } from "react";
import "../@types/index.d.ts";
import Resume from "../assets/Resume.pdf";
import { TagCloud, TagCloudOptions } from "@frank-mayer/react-tag-cloud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquareGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function About() {
  const skills = new Map<string, string[]>();
  skills.set("tech", [
                    "Algorithms",
                    "Data Structures",
                    "C/C++",
                    "Python",
                    "JS/TS",
                    "React",
                    "Solidity",
                    "HTML/CSS",
                    "REST APIs",
                ]);
  skills.set("design", [
                    "UX Principles",
                    "UI Design",
                    "Human-centered Design",
                    "Prototyping",
                    "User Testing",
                    "Interaction Design",
                    "Figma",
                    "Adobe XD",
                    "Processing",
                ]);
  skills.set("leader", [
                    "Data-driven",
                    "Agile",
                    "Kanban",
                    "Lean",
                    "Cross-functional",
                    "Jira",
                    "Competitive Analysis",
                    "Waterfall",
                    "Miro",
                ]);

  const [skillType, setSkillType] = useState("tech");
  
  const [tagCloudOptions, setTagCloudOptions] = useState<TagCloudOptions>({
    radius: Math.min(window.innerWidth / 7, window.innerHeight / 4),
    maxSpeed: "fast",
  });

  useEffect(() => {
    const updateRadius = () => {
      setTagCloudOptions({
        radius: Math.min(window.innerWidth / 7, window.innerHeight / 4),
        maxSpeed: "fast",
      });
    };

    window.addEventListener('resize', updateRadius);

    return () => {
      window.removeEventListener('resize', updateRadius);
    };
  }, []);
  
  return (
    <div className="About" id="About">
      <div className="Frame Main P2">
        <div className="ColumnsWrapper">
          <div className="InfoWrapper">
            <div className="Info">
              <h2 className="font-title">Who Am I?</h2>
              <p className="font-text">
                I&apos;m a{" "}
                <span className="highlight">computer scientist </span>
                with a focus on building things that{" "}
                <span className="highlight">benefit real people</span>. From
                easy-to-use interfaces to decentralized, privacy-preserving
                protocols, what I make has
                <span className="highlight"> humans at the forefront </span>
                of the design process. <br />
                <br />
                I&apos;m now entering my sophomore year at{" "}
                <span className="highlight">
                  Carnegie Mellon&apos;s School of Computer Science
                </span>
                . I initially intended on majoring in CS, as I had planned for
                as long as I knew what a major was. But, as I was introduced to{" "}
                <span className="highlight">Human-Computer Interaction</span>, I
                very quickly realised that it&apos;s the field for me, and by
                the end of my first semester, I was set on that as my major. The
                skills I have developed, and will continue to develop, over the
                duration of this program will enable me to{" "}
                <span className="highlight">
                  build tools to improve lives everywhere
                </span>
                .
              </p>
              <div className="LinkWrapper">
                <p className="Link font-italic">
                  <a href={Resume} rel="noreferrer" target="_blank">
                    <FontAwesomeIcon
                      icon={faArrowUpRightFromSquare as IconProp}
                    />
                    &nbsp;&nbsp;View my resume
                  </a>
                </p>
              </div>
              <div className="SocialWrapper">
                <a href="https://github.com/yousif-alnajjar" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faSquareGithub as IconProp} /></a>
                <a href="https://linkedin.com/in/yousif-alnajjar" rel="noreferrer" target="_blank"><FontAwesomeIcon icon={faLinkedin as IconProp} /></a>
              </div>
            </div>
          </div>
          <div className="Sphere font-text">
            <div className="TagWrapper">
            <h2 className="font-title">What Can I Do?</h2>
            <TagCloud
                options={tagCloudOptions}
            >
                {
                  (skills.get(skillType) || [])
                }
            </TagCloud>
            <div className="Selector">
              <button className={skillType == "tech" ? "active" : "inactive"} onClick={() => setSkillType("tech")}>
                Technical
              </button>

              <button className={skillType == "design" ? "active" : "inactive"} onClick={() => setSkillType("design")}>
                Design
              </button>

              <button className={skillType == "leader" ? "active" : "inactive"} onClick={() => setSkillType("leader")}>
                Leadership
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
